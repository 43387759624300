<template>
  <div class="page">
    <BreadCrumb />
    <div class="page-body">
       <div class="box">
          <div>数据识别：数据元与表字段的关联</div>
          <div class="m-t-20">使用模型：数据元关联分析</div>
          <p class="m-t-30">分析结果</p>
          
          <el-table class="m-t-20"  :data="tableData" fit stripe>
            <el-table-column label="序号" type="index" />
            <el-table-column label="数据元" prop="dataElement" />
            <el-table-column label="数据元所属主题" prop="theme" />
            <el-table-column label="关联表数量" prop="table" />
            <el-table-column label="关联字段数量" prop="field" />
            <el-table-column label="操作">
                <el-button type="primary"  @click="toResult()">查看结果</el-button>
            </el-table-column>
          </el-table>
       </div>
    </div>
  </div>

</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb' //面包屑

export default {
  name: 'dataIdentification',
  //注册组件
  components: {
    BreadCrumb,
  },
  data() {
    return {
      tableData: [
        {
        dataElement: 'xm',
        theme: '自然人基础信息',
        table: '12',
        field:'16'
      },{
        dataElement: 'sfzh',
        theme: '自然人基础信息',
        table: '10',
        field:'10'
      },{
        dataElement: 'xydm',
        theme: '法人单位基础信息',
        table: '11',
        field:'13'
      },
  ],
    }
  },
  //挂在完成 此时一般可以做一些ajax操作，mounted只会执行一次。
  mounted: function () {
  },
  methods: {
    //查询
    toResult() {
      this.$router.push({
        name: 'dataIdentificationResult'
      })
    },
  },
}
</script>

<style scoped>
  .box{
    padding: 0 5vw;
  }
  .box>div{
    font-size: 16px;
  }
  .box>p{
    font-size: 18px;
  }
</style>
